import { useEffect, createContext, useReducer } from "react";

const initialState = {
  isAuthenticated: undefined,
  token: null,
  firstname: "",
  lastname: "",
};

export const AuthContext = createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.access_token);
      localStorage.setItem("firstname", action.payload.firstname);
      localStorage.setItem("lastname", action.payload.lastname);

      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.access_token,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...initialState,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const firstname = localStorage.getItem("firstname");
    const lastname = localStorage.getItem("lastname");

    if (token) {
      dispatch({
        type: "LOGIN",
        payload: {
          access_token: token,
          firstname,
          lastname,
        },
      });
    } else if (state.isAuthenticated) {
      dispatch({
        type: "LOGOUT",
      });
    }
  }, []);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
