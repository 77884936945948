import { useEffect, useRef } from "react";
import About from "./components/AboutUs";
import Contacts from "./components/Contacts";
import Main from "./components/Main";
import ProhibitedItems from "./components/ProhibitedItems";
import Questions from "./components/Questions";
import Services from "./components/Services";
import { useLocation, useNavigate } from "react-router-dom";
import Prices from "./components/Prices";

const landingRoutes = [
  {
    path: "home",
    route: <Main />,
  },
  {
    path: "services",
    route: <Services />,
  },
  {
    path: "pricelist",
    route: <Prices />,
  },
  {
    path: "prohibited",
    route: <ProhibitedItems />,
  },
  {
    path: "about",
    route: <About />,
  },
  // {
  //   path: "news",
  //   route: <NewsList />,
  // },
  {
    path: "faq",
    route: <Questions />,
  },
  {
    path: "contacts",
    route: <Contacts />,
  },
];

const Section = ({ path, route }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const el = document.getElementById(path);
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          navigate(`#${path}`);
          return;
        }
      },
      {
        root: null,
        threshold: 0.5, // set offset 0.1 means trigger if atleast 10% of element in viewport
      }
    );

    observer.observe(el);
  }, [path, navigate]);

  return route;
};

const Landing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let firstHash = useRef("");

  useEffect(() => {
    if (location.hash && !firstHash.current) {
      setTimeout(() => {
        navigate(location.hash);
        const el = document.getElementById(location.hash.slice(1));
        el.scrollIntoView({ behavior: "smooth" });
      }, 50);
      firstHash.current = location.hash;
    }
  }, [location.hash]);

  return (
    <>
      {landingRoutes.map((item) => {
        return <Section route={item.route} path={item.path} key={item.path} />;
      })}
    </>
  );
};

export default Landing;
