import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextValidator } from "react-material-ui-form-validator";
import SERVICES from "services";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  label: {
    margin: "15px 0 5px",
    textAlign: "left",
    fontWeight: 600,
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
  },
}));

const CountriesSelect = ({
  value,
  onFormChange,
  helperText,
  error,
  disabled,
  onCountryChange,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    SERVICES.FILTERS.getCountries().then((res) => {
      setOptions(res.data);
    });
  }, []);

  return (
    <>
      <div className={classes.label}>
        Country <span className={classes.asterisk}>*</span>
      </div>
      <Autocomplete
        disablePortal
        options={options}
        getOptionLabel={(option) => {
          if (option === null) return option;

          if (typeof option === "object") {
            return option.name;
          }
          const opt = options?.find((el) => el.id === option);
          return opt?.name || "";
        }}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value;
        }}
        value={value || null}
        onChange={(event, option) => {
          const newValue = option?.id;
          onFormChange((prevState) => ({
            ...prevState,
            country_id: newValue || null,
          }));
          onCountryChange && onCountryChange(option.code);
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextValidator
            {...params}
            value={value || ""}
            size="small"
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: "nope",
            }}
            validators={["required"]}
            errorMessages={[t("global.required")]}
            helperText={helperText}
            error={error}
          />
        )}
      />
    </>
  );
};

export default CountriesSelect;
