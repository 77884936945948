import { useEffect, useState } from "react";
import SERVICES from "services";
import PrintType_A from "./componentsForPrint/Type_A/PrintType_A";
import PrintType_B from "./componentsForPrint/Type_B/PrintType_B";

const ComponentToPrint = ({ listId, id, printType }) => {
  const [data, setData] = useState(null);

  const [tracking_number, setTrackingNumber] = useState("");

  useEffect(() => {
    SERVICES.ORDERS.getParcelById(listId, id).then((res) => {
      if (res.data.length) {
        setData(res.data[0]);
        setTrackingNumber(
          res.data[0].tracking_code + res.data[0].tracking_number
        );
      }
    });
  }, [id, listId]);

  return (data?.tracking_number && (printType === 'a' ? <PrintType_A data={data} tracking_number={tracking_number} /> : <PrintType_B data={data} tracking_number={tracking_number} />));
};

export default ComponentToPrint;
