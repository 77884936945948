// import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  // Checkbox,
  // CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  // DialogTitle,
  // FormControl,
  // IconButton,
  // InputAdornment,
  // InputLabel,
  // OutlinedInput,
  // TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useContext, useState } from "react";
import logo from "assets/logo.png";
// import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SERVICES from "services";
import { AuthContext } from "context/Auth.context";
import { SettingsContext } from "context/Settings.context";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    flexDirection: "column",
    background: "transparent",
    "& .MuiPaper-root": {
      borderRadius: 12,
    },
    "& .MuiTypography-root": {
      padding: 0,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    "&.MuiDialogContent-root": {
      padding: "45px 40px",
      [theme.breakpoints.down("lg")]: {
        padding: 20,
      },
    },
  },
  titleBlock: {
    paddingBottom: 40,
    maxWidth: 450,
  },
  welcome: {
    fontWeight: 300,
    fontSize: 24,
    lineHeight: 2,
    color: theme.palette.primary.contrastText,
  },
  signInTitle: {
    fontWeight: 600,
    fontSize: 28,
    "& span": {
      color: "#13A800",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
    },
  },
  userName: {
    "&.MuiFormControl-root": {
      marginBottom: 35,
    },
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  password: {
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  rememberBlock: {
    display: "flex",
    justifyContent: "end",
    alignItems: "baseline",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 2,
  },
  checkbox: {
    "&.MuiButtonBase-root": {
      color: theme.palette.primary.main,
      paddingLeft: 0,
    },
  },
  forgot: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  logInBtn: {
    width: "100%",
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 12,
      fontWeight: 700,
      fontSize: 22,
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  },
  bottomSection: {
    display: "flex",
    justifyContent: "space-evenly",
    fontSize: 16,
  },
  signUp: {
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const initialForm = {
  email: "",
  password: "",
  showPassword: false,
  isSubmitting: false,
  errorMessage: null,
  inputErrors: null,
};

const LoginDialog = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const { dispatch: dispatchToast } = useContext(SettingsContext);

  const [user, setUser] = useState(initialForm);

  const handleClose = () => {
    setOpen(false);
  };

  // const [values, setValues] = useState({
  //   password: "",
  //   showPassword: false,
  // });

  const handleChange = (event) => {
    setUser((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClickShowPassword = (item) => {
    setUser({ ...user, showPassword: !user.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setUser((prev) => ({
      ...prev,
      isSubmitting: true,
    }));
    localStorage.removeItem("localDeliveryAddress");
    localStorage.removeItem("localDeliveryCity");
    localStorage.removeItem("localDeliveryStatesId");
    localStorage.removeItem("localDeliveryAddressCount");

    SERVICES.AUTH.login({
      email: user.email,
      password: user.password,
    })
      .then((res) => {
        dispatch({
          type: "LOGIN",
          payload: res.data,
        });
        setUser(initialForm);
        handleClose();
        navigate("/lists", { replace: true });
      })
      .catch((err) => {
        if (typeof err.message === "string") {
          dispatchToast({
            type: "ERROR_TOAST",
            payload: { message: err.message },
          });
        }
      })
      .finally(() => {
        setUser((prev) => ({
          ...prev,
          isSubmitting: false,
        }));
      });
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogContent className={classes.content}>
        <div className={classes.titleBlock}>
          {/* <div className={classes.welcome}>Welcome !</div> */}
          <img src={logo} alt="logo" style={{ marginBottom: 10 }} />
          <div
            className={classes.signInTitle}
            dangerouslySetInnerHTML={{ __html: t("loginDialog.title") }}
          ></div>
        </div>
        <ValidatorForm
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
        >
          <TextValidator
            className={classes.userName}
            fullWidth
            variant="outlined"
            margin="dense"
            label={t("loginDialog.username")}
            onChange={handleChange}
            name="email"
            type="email"
            autoComplete="email"
            value={user.email}
            validators={["required", "isEmail"]}
            errorMessages={[t("global.required"), "Email is invalid."]}
          />
          <TextValidator
            className={classes.userName}
            fullWidth
            margin="dense"
            label={t("loginDialog.password")}
            onChange={handleChange}
            name="password"
            autoComplete="current-password"
            type={user.showPassword ? "text" : "password"}
            validators={["required", "minStringLength:6"]}
            errorMessages={[t("global.required"), "Min 6 characters"]}
            value={user.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {user.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* <FormControl variant="outlined" className={classes.password}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl> */}
          {/* <div className={classes.rememberBlock}>
            <span className={classes.forgot}>Forgot password?</span>
          </div> */}
          <div
            style={{
              margin: "40px 0px",
            }}
          >
            <Button
              className={classes.logInBtn}
              variant="outlined"
              type="submit"
              disabled={user.isSubmitting}
            >
              {t("loginDialog.login")}
            </Button>
          </div>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialog;
