import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Loading() {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: 50,
      }}
    >
      <CircularProgress color="success" />
    </Box>
  );
}
