import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  label: {
    margin: "15px 0 5px",
    textAlign: "left",
    fontWeight: 600,
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
  },
  titleContainer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 12,
    height: 81,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
    marginTop: 30,
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
    },
  },
  icon: {
    marginRight: 10,
  },
  uploadImageBtn: {
    "&.MuiButtonBase-root": {
      width: "100%",
      textTransform: "unset",
      color: theme.palette.primary.main,
      borderRadius: 8,
      fontWeight: 400,
      fontSize: 18,
      padding: "5px 30px",
      margin: "0px 30px 0px 0px",
      borderColor: "#CDCDCD",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: 0,
      },
      "&:hover": {
        background: "white",
        borderColor: "#CDCDCD",
      },
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
        padding: "5px 18px",
        margin: 0,
      },
    },
  },
  uploadImageWrapper: {
    display: "flex",
    justifyContent: "space-around",
    flexFlow: "wrap",
  },
  imageItem: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    margin: "15px 0px",
    position: "relative",
    marginRight: 10,
    width: 180,
    minWidth: 150,
    // marginTop: 10,
  },
  deleteBtn: {
    position: "absolute",
    top: -5,
    right: -5,
    width: 28,
    height: 28,
    background: "red",
    color: "white",
    borderRadius: "50%",
    fontSize: 20,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
