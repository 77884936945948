import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  noList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "60px auto",
  },
  noListSpan: {
    fontWeight: 600,
    fontSize: 24,
    textAlign: "center",
    color: "#A7A7A7",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  listTabs: {
    width: "300px",
    display: "inline-block",
    padding: "25px 0",
    boxShadow: "0px 6px 16px rgba(74, 58, 255, 0.19)",
    borderRadius: 12,
    fontSize: 22,
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: 20,
    },
  },
  pagination: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
      marginTop: 50,
    },
    "& .MuiButtonBase-root": {
      borderRadius: 6,
      fontSize: 12,
      fontWeight: 600,
      borderColor: "#BCBCBC",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      color: "#fff",
      background: theme.palette.primary.main,
      border: "none",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    "& .MuiPaginationItem-icon": {
      color: theme.palette.primary.main,
    },
  },
}));
