import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CustomerSelect from "components/CustomerSelect";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { getCountryCallingCode } from "react-phone-number-input";
import recipient_icon from "assets/icons/recipient.png";
import { useStyles } from "./styles";
import { useCallback, useContext, useEffect, useState } from "react";
import { SettingsContext } from "context/Settings.context";
import SERVICES from "services";
import CountriesSelect from "components/CountriesSelect";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { ImageUpload } from "components/ImageUpload";
import PhoneField from "components/PhoneField";
import { useTranslation } from "react-i18next";
const recipientParams = {
  customer_type_id: "2,3",
};

const affiliationParams = {
  affiliation: 1,
};

const initialState = {
  firstname: "",
  lastname: "",
  country_id: null,
  city: "",
  postal_code: "",
  address: "",
  telephone: "",
  sec_telephone: "",
  email: "",
  passport_dateOfIssue: null,
  passport_number: "",
  customerFile: [],
  states_id: "",
  other_states_name: "",
  contract_id: "",
  company_name: "",
  taxpayer_id: 0,
  country_code: "",
};

const RecipientForm = ({
  customerId,
  setCustomerId,
  senderId,
  disabled,
  isPrinting,
  noEditable,
}) => {
  const classes = useStyles();
  const [form, setForm] = useState(initialState);
  const [inputErrors, setInputErrors] = useState({});
  const [customers, setCustomers] = useState([]);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [deletedFilesIds, setDeletedFilesIds] = useState([]);
  const [states, setStates] = useState([]);
  const { dispatch: dispatchToast } = useContext(SettingsContext);
  const { t } = useTranslation();

  const handleSubmit = () => {
    if(inputErrors?.telephone || inputErrors?.sec_telephone){return}
    setInputErrors({});
    if (form.telephone) {
      setIsSubmiting(true);
      const newForm = {
        ...form,
        passport_dateOfIssue:
          form.passport_dateOfIssue &&
          moment(form.passport_dateOfIssue).format("DD-MM-YYYY"),
        customer_type_id: 2,
        customerFile: form.customerFile.length
          ? form.customerFile.filter((el) => el.data_url).map((el) => el.file)
          : undefined,
        deletedFilesIds:
          deletedFilesIds.length && customerId ? deletedFilesIds : undefined,
        states_id: form.states_id || undefined,
      };

      if (customerId) {
        SERVICES.CUSTOMERS.updateCustomer(newForm, customerId)
          .then(() => {
            getCustomers({ sender_id: senderId });
            dispatchToast({
              type: "SUCCESS_TOAST",
              payload: { message: "Success action" },
            });
          })
          .catch((err) => {
            if (typeof err.message === "string") {
              dispatchToast({
                type: "ERROR_TOAST",
                payload: { message: err.message },
              });
            } else {
              setInputErrors(err.message);
            }
          })
          .finally(() => {
            setIsSubmiting(false);
          });
      } else {
        SERVICES.CUSTOMERS.addCustomer(newForm)
          .then((res) => {
            setCustomerId(res.data.customer_id);
            getCustomers({ sender_id: senderId });
            dispatchToast({
              type: "SUCCESS_TOAST",
              payload: { message: "Success action" },
            });
          })
          .catch((err) => {
            if (typeof err.message === "string") {
              dispatchToast({
                type: "ERROR_TOAST",
                payload: { message: err.message },
              });
            } else {
              setInputErrors(err.message);
            }
          })
          .finally(() => {
            setIsSubmiting(false);
          });
      }
    } else {
      if (!form.telephone)
        setInputErrors((prev) => ({
          ...prev,
          telephone: 'Required field.',
        }));
    }
  };

  const handleChange = (event) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDateChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (value) => {
    setForm((prevState) => ({
      ...prevState,
      customerFile: value,
    }));
  };

  const clearCustomer = () => {
    setCustomerId(null);
    setForm(initialState);
  };

  const handleFileDelete = (id) => {
    if (id) {
      setDeletedFilesIds((prev) => {
        const ids = [...prev];
        ids.push(id);
        return ids;
      });
    }
  };

  const getCustomers = useCallback(
    async (params) => {
      try {
        const newParams = !params
          ? { sender_id: senderId }
          : params.contract_id || params?.telephone
          ? params
          : { ...params, ...affiliationParams };

        const response = await SERVICES.CUSTOMERS.getCustomers({
          ...recipientParams,
          ...newParams,
        });
        setCustomers(response.data);
        return new Promise((res) => res(response));
      } catch (e) {
        return new Promise((_, rej) => rej(e));
      }
    },
    [senderId]
  );

  const onCountryChange = useCallback((code) => {
    setForm((prevState) => ({
      ...prevState,
      states_id: "",
      other_states_name: "",
      telephone: code ? "+" + getCountryCallingCode(code) : "",
      sec_telephone: code ? "+" + getCountryCallingCode(code) : "",
    }));
  }, []);

  useEffect(() => {
    if (senderId) {
      getCustomers();
    }
  }, [getCustomers, senderId]);

  useEffect(() => {
    if (isPrinting === "add") {
      setForm(initialState);
    }
  }, [isPrinting]);

  useEffect(() => {
    if (customerId) {
      SERVICES.CUSTOMERS.getCustomers({ customer_id: customerId }).then(
        (res) => {
          const customer = res.data[0];
          setForm({
            firstname: customer.director_firstname || "",
            lastname: customer.director_lastname || "",
            country_id: customer.country_id,
            city: customer.city || "",
            postal_code: customer.postal_code || "",
            address: customer.customer_address,
            telephone: customer.telephone || "",
            sec_telephone: customer?.sec_telephone || "",
            email: customer.email || "",
            passport_dateOfIssue: customer.passport_dateOfIssue,
            passport_number: customer.passport_number || "",
            customerFile: customer.customerFile,
            states_id: customer.states_id,
            contract_id: customer.contract_id,
            company_name: customer.company_name || "",
            taxpayer_id: customer.taxpayer_id || 0,
            other_states_name: customer.other_states_name || "",
            country_code: customer.country_code || "",
          });
        }
      );
    }
  }, [customerId]);

  useEffect(() => {
    SERVICES.FILTERS.getStates().then((res) => {
      setStates(res.data);
    });
  }, []);

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      onError={() => {
        if (!form.telephone) {
          setInputErrors((prev) => ({
            ...prev,
            telephone: 'Required field.',
          }));
        }
      }}
      style={{ marginTop: 30 }}
    >
      <Grid container rowSpacing={0.5} columnSpacing={2}>
        <Grid item xs={12}>
          <CustomerSelect
            {...{
              label: "Recipient",
              icon: recipient_icon,
              clearCustomer,
              customerId,
              setCustomerId,
              customers,
              getCustomers,
              disableFind: disabled,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.label}>Barekam ID</div>
          <TextValidator
            size="small"
            fullWidth
            name="contract_id"
            value={form.country_code + form.contract_id}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.label}>
            Email {/* <span className={classes.asterisk}>*</span> */}
          </div>
          <TextValidator
            size="small"
            fullWidth
            name="email"
            value={form.email}
            onChange={handleChange}
            validators={[/* "required", */ "isEmail"]}
            errorMessages={[/* 'Required field.', */ "Email is invalid."]}
            helperText={inputErrors.email}
            error={!!inputErrors.email}
            disabled={disabled || !!customerId}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.label}>
            Name <span className={classes.asterisk}>*</span>
          </div>
          <TextValidator
            size="small"
            fullWidth
            name="firstname"
            value={form.firstname}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={['Required field.']}
            helperText={inputErrors.firstname}
            error={!!inputErrors.firstname}
            disabled={disabled || !!customerId}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.label}>
            Surname <span className={classes.asterisk}>*</span>
          </div>
          <TextValidator
            size="small"
            fullWidth
            name="lastname"
            value={form.lastname}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={['Required field.']}
            helperText={inputErrors.lastname}
            error={!!inputErrors.lastname}
            disabled={disabled || !!customerId}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CountriesSelect
            {...{
              value: form.country_id,
              onFormChange: setForm,
              helperText: inputErrors.country_id,
              error: !!inputErrors.country_id,
              disabled: disabled || !!customerId,
              onCountryChange,
            }}
          />
        </Grid>
        {form.country_id === "7" ? (
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Region <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              select
              size="small"
              fullWidth
              name="states_id"
              value={form.states_id}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={['Required field.']}
              // disabled={disabled || !!customerId}
            >
              {states.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </TextValidator>
          </Grid>
        ) : (
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Region <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              size="small"
              fullWidth
              name="other_states_name"
              value={form.other_states_name}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={['Required field.']}
              helperText={inputErrors.other_states_name}
              error={!!inputErrors.other_states_name}
              // disabled={disabled || !!customerId}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <div className={classes.label}>
            City/village <span className={classes.asterisk}>*</span>
          </div>
          <TextValidator
            size="small"
            fullWidth
            name="city"
            value={form.city}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={['Required field.']}
            helperText={inputErrors.city}
            error={!!inputErrors.city}
            disabled={disabled || !!customerId}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.label}>
            Full address <span className={classes.asterisk}>*</span>
          </div>
          <TextValidator
            size="small"
            fullWidth
            name="address"
            value={form.address}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={['Required field.']}
            helperText={inputErrors.address}
            error={!!inputErrors.address}
            disabled={disabled || !!customerId}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.label}>
            Post Index <span className={classes.asterisk}>*</span>
          </div>
          <TextValidator
            size="small"
            fullWidth
            name="postal_code"
            value={form.postal_code}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={['Required field.']}
            helperText={inputErrors.postal_code}
            error={!!inputErrors.postal_code}
            disabled={disabled || !!customerId}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PhoneField
            {...{
              telephoneErr: inputErrors.telephone,
              setInputErrors,
              disabled: disabled || !!customerId,
              value: form.telephone,
              setForm,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PhoneField
            {...{
              telephoneErr: inputErrors?.sec_telephone,
              setInputErrors,
              isRequired: false,
              disabled: disabled || !!customerId,
              value: form.sec_telephone,
              name: "sec_telephone",
              setForm,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.label}>Passport number</div>
          <TextValidator
            size="small"
            fullWidth
            name="passport_number"
            value={form.passport_number}
            onChange={handleChange}
            helperText={inputErrors.passport_number}
            error={!!inputErrors.passport_number}
            disabled={noEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <div className={classes.label}>Passport expiry date</div>
            <div
              className={[
                inputErrors.passport_dateOfIssue ? classes.errorField : "",
              ]}
            >
              <DesktopDatePicker
                value={form.passport_dateOfIssue}
                inputFormat="DD-MM-YYYY"
                name="passport_dateOfIssue"
                onChange={(val) => {
                  if (val && inputErrors.passport_dateOfIssue) {
                    setInputErrors((prev) => {
                      const { passport_dateOfIssue, ...rest } = prev;
                      return rest;
                    });
                  }
                  handleDateChange("passport_dateOfIssue", val);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
                disabled={noEditable}
              />
              <p style={{ display: "none" }}>
                {inputErrors.passport_dateOfIssue}
              </p>
            </div>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ImageUpload
            {...{
              label: "Upload Passport Image",
              handleFileChange,
              files: form.customerFile,
              handleFileDelete,
              disabled: noEditable,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl style={{ width: "100%" }}>
            <div className={classes.label}>
              Legal entity <span className={classes.asterisk}>*</span>
            </div>
            <RadioGroup
              row
              name="taxpayer_id"
              value={form.taxpayer_id}
              onChange={handleChange}
            >
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#13A800",
                      },
                    }}
                  />
                }
                label="Yes"
                disabled={disabled}
              />
              <FormControlLabel
                value="0"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#13A800",
                      },
                    }}
                  />
                }
                label="No"
                disabled={disabled}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {form.taxpayer_id === "1" && (
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Company <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              size="small"
              fullWidth
              name="company_name"
              value={form.company_name}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={['Required field.']}
              helperText={inputErrors.company_name}
              error={!!inputErrors.company_name}
              disabled={disabled}
            />
          </Grid>
        )}
        {!noEditable && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              type="submit"
              className={classes.saveBtn}
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </ValidatorForm>
  );
};

export default RecipientForm;
