import React from "react";
import { makeStyles } from "@mui/styles";
import Barcode from "react-barcode";
import { currencyTypes } from "../../Parcel";
const useStyeles = makeStyles((theme) => ({
  form1SenderReciver: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: 300,
  },
  section1: {
    display: "flex",
    justifyContent: "space-betweens",
    alignItems: "center",
    padding: 10,
    height: 25,
    background: "#f7f3f2",
  },
  section1Content: {
    borderRight: "1px solid #ddd",
    marginRight: 20,
    height: "100%",
    fontSize: 11,
    display: "flex",
    alignItems: "center",
    width: "26%",
  },
  input: {
    "&:checked": {
      content: "2714",
      fontSize: "1.5em",
      color: "#fff",
    },
  },
  section2: {
    height: "auto",
    marginTop: 5,
    display: "flex",
    zIndex: 5,
    background: "#fff",
    border: "1px solid #999",
  },
  totalInfo: {
    width: "16%",
    borderLeft: "1px solid #999",
    paddingBottom: 3,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  totalInfo2: {
    width: "16%",
    height: "max-content",
    borderLeft: "1px solid #999",
    paddingBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  totalTitle: {
    fontWeight: 600,
    fontSize: 11,
    textAlign: "center",
    whiteSpace: "pre",
    marginTop: 3,
    marginBottom: 5,
  },
  totalInfos: {
    whiteSpace: "pre",
    textAlign: "center",
    fontSize: 10,
  },
  totalInfos2: {
    marginTop: 10,
    whiteSpace: "pre",
    textAlign: "center",
    fontSize: 11,
    fontWeight: 300,
  },
  section3: { display: "flex", height: "max-content" },
  form1BarCode: {
    width: "max-content",
    height: "max-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: 0,
    bottom: 0,
    "& text": {
      fontSize: "30px!important",
    },
  },
}));

const Form1RightSection = ({ data, tracking_number }) => {
  const classes = useStyeles();
  return (
    <div className={classes.form1SenderReciver}>
      <div className={classes.section1}>
        <div className={classes.section1Content}>Customer declaration</div>
        <div className={classes.section1Content}>
          Item maybe opened officially
        </div>
        <div style={{ width: "46%", fontSize: 10 }}>
          <div>Package type</div>
          <div>
            <input
              type="checkbox"
              checked={data?.parcel_packaging === "1"}
              className={classes.input}
              readOnly
            />
            Box
            <input
              type="checkbox"
              checked={data?.parcel_packaging === "2"}
              className={classes.input}
              readOnly
            />
            Bag
            <input
              type="checkbox"
              className={classes.input}
              checked={
                data?.parcel_packaging !== "1" && data?.parcel_packaging !== "2"
              }
              readOnly
            />
            Other
          </div>
        </div>
      </div>
      <div className={classes.section2}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "52%",
            height: "100%",
            alignItems: "start",
          }}
        >
          <div
            style={{
              fontSize: 13,
              fontWeight: 600,
              paddingLeft: 5,
              marginBottom: 5,
              marginTop: 3,
              minHeight: 18,
            }}
          >
            Description of contents
          </div>
          <div
            style={{
              paddingLeft: 5,
              whiteSpace: "pre",
              fontSize: 10,
              marginBottom: 3,
            }}
          >
            {data?.content &&
              data.content.map((el) => el?.content_types_name + "\n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>{"Quantity"}</div>
          <div className={classes.totalInfos}>
            {data?.content && data.content.map((el) => "1 pcs. \n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>{"Gross (kg)"}</div>
          <div className={classes.totalInfos}>
            {data?.content && data.content.map((el) => el?.weight + " kg. \n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>{`Value (${currencyTypes["1"/* data.currency_type_id */]})`}</div>
          <div className={classes.totalInfos}>
            {data?.content && data.content.map((el) => el?.price + ` ${currencyTypes["1symbol"/* data.currency_type_id + "symbol" */] } \n`)}
          </div>
        </div>
      </div>
      <div className={classes.section3}>
        <div style={{ width: "68%" }}></div>
        <div className={classes.totalInfo2}>
          <div className={classes.totalTitle}>{"Total \ngross"}</div>
          <div className={classes.totalInfos2}>{data?.parcel_weight} kg</div>
        </div>
        <div className={classes.totalInfo2}>
          <div className={classes.totalTitle}>{"Total \nvalue"}</div>
          <div className={classes.totalInfos2}>
            {data?.content.reduce((price, elem) => {
              price += +elem.price;
              return price;
            }, 0)}{" "}
            {currencyTypes['1symbol'/* data.currency_type_id */] }
          </div>
        </div>
      </div>
      <div className={classes.form1BarCode}>
        {!!tracking_number && (
          <Barcode
            value={tracking_number}
            // displayValue={false}
            height={48}
            width={1.8}
          />
        )}
      </div>
    </div>
  );
};

export default Form1RightSection;
