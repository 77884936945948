import React from "react";
import { makeStyles } from "@mui/styles";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import { currencyTypes } from "../../Parcel";
const useStyeles = makeStyles((theme) => ({
  form1SenderReciver: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: 420,
  },
  section1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 16,
    marginBottom: 30,
    height: 30,
  },
  section1Content: {
    borderRight: "2px solid #ddd",
    marginRight: 20,
    height: "100%",
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    width: "26%",
  },
  input: {
    "&:checked": {
      content: "2714",
      fontSize: "1.5em",
      color: "#fff",
    },
  },
  section2: {
    height: "auto",
    marginTop: 2,
    display: "flex",
    zIndex: 5,
    background: "#fff",
    border: "1.5px solid #000",
  },
  totalInfo: {
    width: "19%",
    borderLeft: "1.5px solid #000",
    paddingBottom: 3,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  totalInfo2: {
    width: "16%",
    height: "max-content",
    borderLeft: "1px solid #999",
    paddingBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  totalTitle: {
    fontWeight: 600,
    fontSize: 13,
    textAlign: "center",
    whiteSpace: "pre",
    marginTop: 3,
    marginBottom: 10,
  },
  totalInfos: {
    whiteSpace: "pre",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600,
  },
  totalInfos2: {
    marginTop: 10,
    whiteSpace: "pre",
    textAlign: "center",
    fontSize: 40,
    fontWeight: 600,
  },
  section3: { display: "flex", height: "max-content" },
  form1BarCode: {
    width: "max-content",
    height: "max-content",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: 'column',
    alignItems: "center",
    position: "absolute",
    left: 0,
    bottom: -20,
  },
  displayValue: {
    width: "max-content",
    height: "max-content",
    position: 'relative',
    display: 'flex',
    fontSize: "32px !important",
    marginBottom: '10px',
    fontWeight: "900 !important",
    fontFamily: 'sans-serif !important',
    "& .val": {
      marginTop: "-8px",
    },
  },
}));

const Form1RightSection = ({ data, tracking_number }) => {
  const classes = useStyeles();

  let content = data.content.sort((a, b) => +a.weight > +b.weight ? -1 : 1).slice(0,10)
  return (
    <div className={classes.form1SenderReciver}>
      <div className={classes.section1}>
        <Barcode
          value={tracking_number}
          displayValue={false}
          height={57}
          width={2}
        />
        <QRCode
              value={`https://barekam.am/tracking/${data?.tracking_code}${data?.tracking_number}`}
              style={{ width: 85, height: 85, margin: "2px 0" }}
        />
      </div>
      <div className={classes.section2}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "50%",
            height: "100%",
            alignItems: "start",
          }}
        >
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              paddingLeft: 5,
              marginBottom: 10,
              marginTop: 3,
              minHeight: 12,
            }}
          >
            Description of contents
          </div>
          <div
            style={{
              paddingLeft: 5,
              whiteSpace: "pre",
              fontSize: 14,
              fontWeight: 600,
              marginBottom: 3,
            }}
          >
            {data?.content &&
              content.map((el) => el?.content_types_name?.trim()?.length > 27 ? el?.content_types_name.trim().substring(0, 25) + "...\n" : el?.content_types_name?.trim() + "\n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>Quantity</div>
          <div className={classes.totalInfos}>
            {data?.content && content.map((el) => "1 pcs. \n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>Gross (kg)</div>
          <div className={classes.totalInfos}>
            {data?.content && content.map((el) => el?.weight + " kg. \n")}
          </div>
        </div>
        <div className={classes.totalInfo}>
          <div className={classes.totalTitle}>Value ({currencyTypes["1"/* data.currency_type_id */]})</div>
          <div className={classes.totalInfos}>
            {data?.content && content.map((el) => el?.price + ` ${currencyTypes["1symbol"/* data.currency_type_id + "symbol" */] } \n`)}
          </div>
        </div>
      </div>
      {/* PRICE */}
      <div className={classes.section3}>
        <div style={{ width: "69%" }}></div>
        <div className={classes.totalInfo}>
          {/* <div className={classes.totalTitle}>{"Total \ngross"}</div> */}
          <div className={classes.totalInfos}>{data?.parcel_weight} kg</div>
        </div>
        <div className={classes.totalInfo}>
          {/* <div className={classes.totalTitle}>{"Total \nvalue"}</div> */}
          <div className={classes.totalInfos}>
            {data?.content.reduce((price, elem) => {
              price += +elem.price;
              return price;
            }, 0)}{" "}
            {currencyTypes["1symbol"/* data.currency_type_id + "symbol" */] }
          </div>
        </div>
      </div>
      <div className={classes.form1BarCode}>
        {!!tracking_number && (
          <Barcode
            value={tracking_number}
            height={82}
            displayValue={false}
            width={3}
          />
        )}
        <div className={classes.displayValue}>
          <div className="val">{tracking_number}</div></div>
      </div>
    </div>
  );
};

export default Form1RightSection;
