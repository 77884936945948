import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  checkbox: {
    "&.MuiCheckbox-root.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  box: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    border: "1px solid #A7A7A7",
    borderRadius: 12,
    padding: "20px",
  },
  track: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  trackNumberBlock: {
    display: "flex",
    alignItems: "center",
    marginLeft: 50,
  },
  titleSpan: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.4,
    color: "#A7A7A7",
    paddingBottom: 5,
    [theme.breakpoints.down("sm")]: {
      marginRight: 10,
      fontSize: 14,
    },
  },
  trackingTitle: {
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.4,
    color: "#A7A7A7",
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      marginRight: 10,
      fontSize: 14,
    },
  },
  info: {
    fontWeight: "bolder",
    fontSize: 20,
    letterSpacing: 0.4,
    color: "#252733",
  },
  sendRec: {
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center",
    letterSpacing: 0.4,
    color: theme.palette.primary.main,
    padding: "10px 0px",
    display: "flex",
    justifyContent: "space-around",
  },
  sendTo: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "start",
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: 0.4,
    margin: "15px 0px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  textIcon: {
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },
  icon: {
    marginRight: 10,
  },
  delInfo: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  eachInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  courierInfo: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    padding: "20px 0 0",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  printBtn: {
    maxWidth: 170,
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: "rgba(87, 187, 71, 0.2)",
      color: "#000",
      borderRadius: 8,
      fontWeight: 700,
      fontSize: 16,
      marginLeft: 10,
      padding: "5px 30px",
      "&:hover": {
        background: "rgba(87, 187, 71, 0.2)",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "unset",
        marginTop: 10,
      },
    },
  },
}));
