import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  profile: {
    "&  .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiSelect-select": {
      textAlign: "left",
    },
  },
  label: {
    margin: "15px 0 5px",
    textAlign: "left",
    fontWeight: 600,
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
  },
  phoneInput: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "12px",
    padding: "0px 15px",
    "& .PhoneInputInput": {
      border: "none",
      fontSize: 16,
      outline: "none",
      height: "1.4375em",
      padding: "7.5px 0",
    },
    "& .PhoneInput--focus": {
      border: "1px solid #13A800",
    },
    "& .PhoneInputCountrySelectArrow": {
      border: "none",
    },
  },
  gridContainer: {
    textAlign: "start",
  },
  autocomplete: {
    "& .MuiInputBase-root": {
      borderRadius: 12,
      width: "100%",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  postIndex: {
    "&.MuiFormControl-root": {
      // marginBottom: 20,
      [theme.breakpoints.down("lg")]: {
        marginBottom: 10,
      },
    },
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiFormLabel-asterisk": {
      color: "#FF0B21",
      fontSize: 18,
      fontWeight: 700,
    },
  },
  inputTitle: {
    display: "flex",
    fontWeight: 1000,
    lineHeight: 2,
    color: "#252733",
    fontSize: 16,
    letterSpacing: "0.4px",
  },
  input: {
    "&.MuiFormControl-root": {
      marginBottom: 35,
    },
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  btn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 12,
      fontWeight: 700,
      fontSize: 22,
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  },
  changePass: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 4,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  errorField: {
    position: "relative",
    "& p": {
      position: "absolute",
      bottom: -25,
      margin: "4px 14px 0px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
      color: "#d32f2f",
      display: "block!important",
    },
    "& .PhoneInput": {
      borderColor: "#d32f2f",
    },
  },
}));
