import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  label: {
    margin: "15px 0 5px",
    textAlign: "left",
    fontWeight: 600,
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
  },
  titleContainer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 12,
    height: 81,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
    marginTop: 30,
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
    },
  },
  icon: {
    marginRight: 10,
  },
  addBtn: {
    "&.MuiButtonBase-root": {
      marginTop: 20,
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 10,
      fontWeight: 700,
      fontSize: 16,
      border: "none",
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
        border: "none",
      },
    },
  },
  removeBtn: {
    "&.MuiButtonBase-root": {
      marginTop: 20,
      textTransform: "unset",
      background: theme.palette.error.main,
      color: "white",
      borderRadius: 10,
      fontWeight: 700,
      fontSize: 16,
      border: "none",
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.error.main,
        border: "none",
      },
    },
  },
  contentTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentTitle: {
    fontSize: 22,
    fontWeight: 600,
    textAlign: "left",
    marginTop: 20,
  },
}));
