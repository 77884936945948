import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  titleContainer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 12,
    height: 81,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
    marginTop: 30,
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    color: "#f0ad4e",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
    },
  },
  icon: {
    marginRight: 10,
  },
  parcelReservation: {
    "&  .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  sender: {
    display: "flex",
    justifyContent: "space-between",
    background: "#FFFFFF",
    position: "relative",
    // border: "1px solid #A7A7A7",
    borderRadius: 12,
    padding: 25,
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 15,
    },
  },
  senderLeftSide: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: "0.4",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
    },
  },
  findBtn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 10,
      fontWeight: 700,
      fontSize: 16,
      border: "none",
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
        border: "none",
      },
    },
  },
  findListContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    background: "white",
    width: "100%",
    // marginLeft: 10,
    // [theme.breakpoints.down("sm")]: {
    //   width: "95%",
    // },
  },
  findListInput: {
    "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
      padding: "25px",
      [theme.breakpoints.down("sm")]: {
        padding: "30px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A7A7A7",
    },
    "&:hover": {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #A7A7A7",

        // border: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        // borderColor: "white",
        borderRadius: 12,
      },
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "white",
      borderRadius: 12,
    },
  },
  findTextField: {
    "& .MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover": {
      "& .MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
    },
  },
  form: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      marginLeft: 12,
      marginTop: 8,
      justifyContent: "space-between",
    },
    "& .MuiFormControlLabel-root": {
      border: "1px solid #CDCDCD",
      borderRadius: "12px",
      //   maxWidth: "223px",
      width: "49%",
      height: "56px",
      marginRight: 0,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "unset",
    },
    "& .MuiRadio-root.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  radioSelect: {
    "&.MuiFormLabel-root": {
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: "0.4px",
      color: theme.palette.primary.contrastText,
    },
    "& .MuiFormLabel-asterisk": {
      color: "#FF0B21",
      fontSize: 20,
      fontWeight: 700,
    },
    "& .MuiTypography-root": {
      fontSize: 19,
      fontWeight: "bolder",
    },
  },
  inputs: {
    display: "flex",
    flexDirection: "column !important",
    textAlign: "start",
  },
  label: {
    margin: "15px 0 5px",
    textAlign: "left",
    fontWeight: 600,
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
  },
  input: {
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: theme.palette.primary.main,
    //   borderWidth: 1,
    // },
    // "& .MuiInputLabel-root.Mui-focused": {
    //   color: theme.palette.primary.main,
    // },
  },
  autocomplete: {
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
  },
  phoneInput: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "12px",
    padding: "0px 15px",
    "& .PhoneInputInput": {
      border: "none",
      fontSize: 16,
      outline: "none",
      height: "1.4375em",
      padding: "7.5px 0",
    },
    "& .PhoneInput--focus": {
      border: "1px solid #13A800",
    },
    "& .PhoneInputCountrySelectArrow": {
      border: "none",
    },
  },
  fixedWidthContainer: {
    width: "87%",
    maxWidth: 1440,
    margin: "20px auto",
    color: theme.palette.primary.contrastText,
    scrollMargin: "100px",
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },
  },
  container: {
    // width: '50%'
    // margin: '0 auto'
  },
  sender: {
    display: "flex",
    justifyContent: "space-between",
    background: "#FFFFFF",
    position: "relative",
    // border: "1px solid #A7A7A7",
    borderRadius: 12,
    padding: 25,
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 15,
    },
  },
  senderLeftSide: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: "0.4",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 8,
    },
  },
  findListContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    background: "white",
    width: "100%",
    // marginLeft: 10,
    // [theme.breakpoints.down("sm")]: {
    //   width: "95%",
    // },
  },
  findListInput: {
    "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
      padding: "25px",
      [theme.breakpoints.down("sm")]: {
        padding: "30px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A7A7A7",
    },
    "&:hover": {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #A7A7A7",

        // border: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        // borderColor: "white",
        borderRadius: 12,
      },
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      // borderColor: "white",
      borderRadius: 12,
    },
  },

  findTextField: {
    "& .MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover": {
      "& .MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
    },
  },
  form: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      marginLeft: 12,
      marginTop: 8,
      justifyContent: "space-between",
    },
    "& .MuiFormControlLabel-root": {
      border: "1px solid #CDCDCD",
      borderRadius: "12px",
      //   maxWidth: "223px",
      width: "49%",
      height: "56px",
      marginRight: 0,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "unset",
    },
    "& .MuiRadio-root.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  radioSelect: {
    "&.MuiFormLabel-root": {
      fontWeight: 700,
      fontSize: 16,
      letterSpacing: "0.4px",
      color: theme.palette.primary.contrastText,
    },
    "& .MuiFormLabel-asterisk": {
      color: "#FF0B21",
      fontSize: 20,
      fontWeight: 700,
    },
    "& .MuiTypography-root": {
      fontSize: 19,
      fontWeight: "bolder",
    },
  },
  listAddress: {
    fontSize: 16,
    letterSpacing: 0.4,
    color: "#A7A7A7",
    maxWidth: "70%",
    padding: "0px 30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  gridContainer: {
    textAlign: "start",
  },
  inputs: {
    display: "flex",
    flexDirection: "column !important",
    textAlign: "start",
  },
  inputTitle: {
    fontWeight: 1000,
    lineHeight: 2,
    color: "#252733",
    fontSize: 16,
    letterSpacing: "0.4px",
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
  },
  input: {
    "&.MuiFormControl-root": {
      marginBottom: 10,
      width: "100%",
    },
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiFormLabel-asterisk": {
      color: "#FF0B21",
      fontSize: 18,
      fontWeight: 700,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  autocomplete: {
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
  },
  phoneInput: {
    height: "50px",
    marginBottom: 25,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "12px",
    padding: "0px 15px",
    "& .PhoneInputInput": {
      border: "none",
      height: 40,
      fontSize: 16,
      outline: "none",
    },
    "& .PhoneInput--focus": {
      border: "1px solid #13A800",
    },
    "& .PhoneInputCountrySelectArrow": {
      border: "none",
    },
  },
  saveBtn: {
    marginTop: 40,
    marginBottom: 70,
  },
  changePass: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 4,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  form: {
    // width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      // marginLeft: 12,
      marginTop: 8,
      // justifyContent: "space-between",
    },
    "& .MuiFormControlLabel-root": {
      // border: "1px solid #CDCDCD",
      // borderRadius: "12px",
      //   maxWidth: "223px",
      // width: "49%",
      // height: "56px",
      // marginRight: 0,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "unset",
    },
    "& .MuiRadio-root.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  uploadPassport: {
    display: "flex",
    flexDirection: "column",
    borderStyle: "dashed ",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 20,
    padding: "29px 0px",
    letterSpacing: "0.4px",
    border: "1px dashed #CDCDCD",
    borderRadius: 12,
  },
  uploadImageBtn: {
    "&.MuiButtonBase-root": {
      width: "100%",
      textTransform: "unset",
      color: theme.palette.primary.main,
      borderRadius: 8,
      fontWeight: 400,
      fontSize: 18,
      padding: "5px 30px",
      margin: "0px 30px 0px 0px",
      borderColor: "#CDCDCD",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: 0,
      },
      "&:hover": {
        background: "white",
        borderColor: "#CDCDCD",
      },
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
        padding: "5px 18px",
        margin: 0,
      },
    },
  },
  uploadImageWrapper: {
    display: "flex",
    justifyContent: "space-around",
    flexFlow: "wrap",
  },
  imageItem: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    margin: "15px 0px",
    position: "relative",
    marginRight: 10,
    width: 180,
    minWidth: 150,
    // marginTop: 10,
  },
  deleteBtn: {
    position: "absolute",
    top: -5,
    right: -5,
    width: 28,
    height: 28,
    background: "red",
    color: "white",
    borderRadius: "50%",
    fontSize: 20,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  information: {
    margin: "25px 0px",
    display: "flex",
    background: "#FFFFFF",
    border: "1px solid #A7A7A7",
    borderRadius: 12,
    padding: 25,
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: 0.4,
    color: theme.palette.primary.main,
  },
  bottomGrid: {
    width: "60% !important",
    display: "flex",
    margin: "0 auto !important",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      width: "unset !important",
      margin: "unset",
    },
    [theme.breakpoints.down("lg")]: {
      "&.MuiGrid-root>.MuiGrid-item": {
        paddingLeft: 0,
      },
    },
  },

  addInternal: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
  internalDelBtn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: "#FCB331",
      color: "white",
      borderRadius: 12,
      border: "none",
      fontWeight: 700,
      fontSize: 16,
      padding: "5px 30px",
      "&:hover": {
        background: "#FCB331",
        border: "none",
      },
    },
  },
  internalDeliverySection: {
    width: "60%",
    margin: "30px auto",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      margin: "30px 0px",
    },
  },
  addressForm: {
    // width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      marginTop: 8,
    },
    "& .MuiFormControlLabel-root": {
      // border: "1px solid #CDCDCD",
      // borderRadius: "12px",
      //   maxWidth: "223px",
      // width: "49%",
      // height: "56px",
      // marginRight: 0,
      fontSize: 16,
      color: "#A7A7A7",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "unset",
    },
    "& .MuiRadio-root.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  additionalInfo: {
    fontSize: 16,
    letterSpacing: 0.2,
    fontWeight: 500,
    color: "#26341F",
    background: "rgba(252, 179, 49, 0.08)",
    borderRadius: 10,
    margin: "30px 0px",
    padding: "15px 10px",
  },
  totalPrice: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  totalPriceSpan: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 0.4,
  },
  price: {
    fontWeight: 600,
    fontSize: 24,
    letterSpacing: 0.4,
    margin: "15px 0px",
  },
  btn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: "#f0ad4e",
      color: "white",
      borderRadius: 10,
      fontWeight: 700,
      fontSize: 16,
      border: "none",
      padding: "5px 30px",
      "&:hover": {
        background: "#f0ad4e",
        border: "none",
      },
    },
  },
  addressCard: {
    "& .MuiButtonBase-root": {
      color: theme.palette.primary.main,
      margin: "0 auto",
    },
  },
  removeBtn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.error.main,
      color: "white",
      borderRadius: 10,
      fontWeight: 700,
      fontSize: 16,
      border: "none",
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.error.main,
        border: "none",
      },
    },
  },
}));
