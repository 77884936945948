import { useCallback, useEffect, useState } from "react";
import { Grid, Pagination, useMediaQuery, useTheme } from "@mui/material";
import noList from "assets/icons/noClosedLists.png";
import SERVICES from "services";
import PackageList from "./components/List";
import { useStyles } from "./styles";
import Loading from "components/Loading";
import SectionTitle from "components/SectionTitle";

export const LIST_TYPES = {
  openLists: "openList",
  closedLists: "closedList",
};

const Lists = () => {
  const classes = useStyles();
  const [packageLists, setPackageLists] = useState([]);
  const [listType, setListType] = useState(LIST_TYPES.openLists);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 4;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const handleChange = (e, p) => {
    setCurrentPage(p);
  };

  const getListData = useCallback((params) => {
    setLoading(true);
    SERVICES.ORDERS.getOrders(params)
      .then((res) => {
        setPackageLists(res.data);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (listType === LIST_TYPES.openLists) {
      getListData();
    } else {
      getListData({ status: 2 });
    }
  }, [listType, getListData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionTitle title="Lists" />
      </Grid>
      <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
        <div
          className={classes.listTabs}
          style={{
            border:
              listType === LIST_TYPES.openLists
                ? "2px solid #FCB331"
                : "2px solid #A7A7A7",
            fontWeight: listType === LIST_TYPES.openLists ? 600 : "unset",
          }}
          onClick={() => {
            setListType(LIST_TYPES.openLists);
          }}
        >
          Open Lists
        </div>
      </Grid>
      <Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
        <div
          className={classes.listTabs}
          onClick={() => {
            setListType(LIST_TYPES.closedLists);
          }}
          style={{
            border:
              listType === LIST_TYPES.closedLists
                ? "2px solid #D9534F"
                : "2px solid #A7A7A7 ",
            fontWeight: listType === LIST_TYPES.closedLists ? 600 : "unset",
          }}
        >
          Closed Lists
        </div>
      </Grid>

      {loading ? (
        <Loading />
      ) : (
        <>
          {!packageLists.length ? (
            <Grid item xs={12}>
              <div className={classes.noList}>
                <img src={noList} alt="no list" />
                <span className={classes.noListSpan}>
                  There are no lists attached to you yet.
                </span>
              </div>
            </Grid>
          ) : (
            packageLists
              .slice(indexOfFirstRecord, indexOfLastRecord)
              .map((list) => {
                return (
                  <Grid key={list.id} item xs={12} lg={6}>
                    <PackageList
                      {...{
                        listType,
                        isMobile,
                        list,
                      }}
                    />
                  </Grid>
                );
              })
          )}

          <Grid item xs={12}>
            <Pagination
              className={classes.pagination}
              count={Math.ceil(packageLists.length / recordsPerPage)}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Lists;
