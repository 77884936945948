import axiosClient from "../utils/axiosClient";
import api from "../api";
import { formData } from "utils/helpers/formData";

const getCostHistory = async () => {
  const response = await axiosClient.get(api.COST.COST);
  return response.data;
};

const getCostHistoryId = async (id) => {
  const response = await axiosClient.get(api.COST.COST + `/${id}`);
  return response.data;
};

const deleteCost = async (data, id) => {
  const response = await axiosClient.post(api.COST.COST + "/" + id, data);
  return response.data;
};

const addCost = async (data) => {
  const response = await axiosClient.post(api.COST.COST, formData(data), {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const editCost = async (data, id) => {
  const response = await axiosClient.post(
    `${api.COST.COST}/${id}`,
    formData(data),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

const service = {
  getCostHistory,
  getCostHistoryId,
  addCost,
  editCost,
  deleteCost,
};

export default service;
