import { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate, useParams } from "react-router-dom";
import SectionTitle from "components/SectionTitle";
import SenderForm from "./components/SenderForm";
import RecipientForm from "./components/RecipientForm";
import GrossForm from "./components/GrossForm";
import ContentForm from "./components/ContentForm";
import _ from "lodash";
import PaymentForm from "./components/PaymentForm";
import SERVICES from "services";
import { SettingsContext } from "context/Settings.context";
import InternalDelivery from "./components/InternalDelivery";
import { useStyles } from "./styles";

const defaultContent = {
  quantity: 1,
  content_id: null,
  other_name: "",
  weight: "",
  price: "",
};

const defaultForm = {
  customer_id: null,
  receiver_id: null,
  parcel_weight: 0,
  parcel_declared_value: 0,
  parcel_packaging: 1,
  parcel_insurance: 1,
  dimension_calculation: 2,
  dimension_length: "",
  dimension_width: "",
  dimension_height: "",
  files: [],
  content: [defaultContent],
  price_per_kg: 4,
  sender: {},
  parcels_discount_type_id: "",
  parcel_discount: 0,
  payment_cash: "",
  payment_bank_transfer: "",
  parcel_comments: "",
  price: "",
  currency_type_id: "",
  remainder: "",
};

const defaultInternalDeliveryForm = {
  parcel_local_delivery: "2",
  local_delivery_states_id: "",
  local_delivery_full_address: "",
  local_delivery_address_type: 1,
  local_delivery_city: "",
  local_delivery_type: 1,
  local_delivery_price: 0,
};

const ParcelReservation = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [form, setForm] = useState(defaultForm);
  const [internalDeliveryForm, setInternalDeliveryForm] = useState(
    defaultInternalDeliveryForm
  );
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [isPrinting, setPrinting] = useState("");
  const [isPressedLocalAddress, setIsPressed] = useState(false);
  const [deletedFilesIds, setDeletedFilesIds] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const { id: listId, parcelId, warehouseId } = useParams();
  const { dispatch: dispatchToast } = useContext(SettingsContext);
  const [isChanged, setChanged] = useState(parcelId ? true : false);

  const handleChange = (event) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleInternalDeliveryChange = (event, val = false, chengedValues) => {
    const obj = { ...internalDeliveryForm,  parcel_weight: form.parcel_weight };
    if (val) {
      setInternalDeliveryForm(defaultInternalDeliveryForm);
      return;
    } else {
      setInternalDeliveryForm((prevState) => {
        return {
          ...prevState,
          ...chengedValues,
          parcel_weight: form.parcel_weight,
          [event.target.name]: event.target.value,
        };
      });
    }
    obj[event.target.name] = event.target.value;
    return obj;
  };

  const handleContentChange = (event, index) => {
    setForm((prevState) => {
      const newContent = _.cloneDeep(prevState.content);
      newContent[index][event.target.name] = event.target.value;
      return {
        ...prevState,
        content: newContent,
      };
    });
  };

  const addContent = () => {
    setForm((prevState) => {
      const newContent = _.cloneDeep(prevState.content);
      newContent.push(defaultContent);
      return {
        ...prevState,
        content: newContent,
      };
    });
  };

  const deleteContent = (event, index) => {
    setForm((prevState) => {
      const newContent = _.cloneDeep(prevState.content);
      newContent.splice(index, 1);
      return {
        ...prevState,
        content: newContent,
      };
    });
  };

  const handleFileDelete = (id) => {
    if (id) {
      setDeletedFilesIds((prev) => {
        const ids = [...prev];
        ids.push(id);
        return ids;
      });
    }
  };

  const handleFileChange = (value) => {
    setForm((prevState) => ({
      ...prevState,
      files: value,
    }));
  };

  let localDeliveryPrice =
    internalDeliveryForm.parcel_local_delivery === "1" &&
    internalDeliveryForm.local_delivery_type === 1 &&
    internalDeliveryForm.local_delivery_states_id
      ? internalDeliveryForm.local_delivery_states_id === "1"
        ? Number(form.parcel_weight) > 30 ? 10 : 5
        : Number(form.parcel_weight) > 30 ? 15 : 7
      : 0;


  if(internalDeliveryForm.local_delivery_type === 3 && internalDeliveryForm.local_delivery_full_address === 'ք․ Գյումրի, Գյումրու գրասենյակ'){
    localDeliveryPrice = Number(form.parcel_weight) > 30 ? 10 : 5
  }

  const localDeliveryAddress = localStorage.getItem("localDeliveryAddress");
  const localDeliveryCity = localStorage.getItem("localDeliveryCity");
  const localDeliveryStatesId = localStorage.getItem("localDeliveryStatesId");
  const localDeliveryAddressCount = localStorage.getItem(
    "localDeliveryAddressCount"
  );

  const isSameAddress =
    localDeliveryAddress &&
    localDeliveryCity?.toLocaleLowerCase() ===
      internalDeliveryForm.local_delivery_city?.toLowerCase() &&
    localDeliveryAddress?.toLowerCase() ===
      internalDeliveryForm.local_delivery_full_address?.toLowerCase() &&
    localDeliveryStatesId === internalDeliveryForm.local_delivery_states_id;

  if (isSameAddress && !parcelId) {
    if (localDeliveryAddressCount === "1") {
      localDeliveryPrice = 0;
    } else if (localDeliveryAddressCount > 1) {
      localDeliveryPrice = 1;
    }
  }
  if (+internalDeliveryForm.parcel_local_delivery === 2) {
    localDeliveryPrice = 0;
  }

  const dimensionPrice =
    (form.dimension_height * form.dimension_length * form.dimension_width) /
    6000;

  const grossPrice =
    form.dimension_calculation === "1" && dimensionPrice
      ? form.price_per_kg * dimensionPrice
      : form.parcel_weight * form.price_per_kg;

  let totalPayment = (
    grossPrice.toFixed(3) -
    form.parcel_discount +
    localDeliveryPrice
  ).toFixed(2);

  let remainder =
    totalPayment - (form.payment_cash || 0) - (form.payment_bank_transfer || 0);
  if (isChanged && +internalDeliveryForm.parcel_local_delivery === 1) {
    remainder = +form.remainder;
    totalPayment = +form.price;
  }

  const grossWeightStatus =
    form.parcel_weight -
      form.content.reduce(
        (weightSum, a) => +(weightSum + +a.weight).toFixed(2),
        0
      ) ===
    0;
  const grossPriceStatus =
    form.parcel_declared_value -
      form.content.reduce(
        (weightSum, a) => +(weightSum + +a.price).toFixed(2),
        0
      ) ===
    0;

  const handleSubmit = () => {
    setInputErrors({});
    if (!grossWeightStatus) {
      dispatchToast({
        type: "ERROR_TOAST",
        payload: {
          message:
            "The total weight of the package does not match the amount weight of the contents",
        },
      });
    } else if (!grossPriceStatus) {
      dispatchToast({
        type: "ERROR_TOAST",
        payload: {
          message:
            "The total cost of the package does not match the amount cost of the contents",
        },
      });
    } else {
      setIsSubmiting(true);
      SERVICES.ORDERS.addParcel(
        {
          ...form,
          parcel_local_delivery: "2",
          ...(internalDeliveryForm.parcel_local_delivery === "1" && {
            ...internalDeliveryForm,
            local_delivery_price: Number(
              internalDeliveryForm.local_delivery_price || 0
            ).toFixed(),
          }),
          files: form.files.length
            ? form.files.filter((el) => el.data_url).map((el) => el.file)
            : undefined,
          deletedFilesIds: deletedFilesIds.length ? deletedFilesIds : undefined,
          payment_cash: form.payment_cash || undefined,
          payment_bank_transfer: form.payment_bank_transfer || undefined,
          parcels_discount_type_id: form.parcels_discount_type_id || undefined,
          remainder,
          price: undefined,
        },
        listId,
        parcelId
      )
        .then((res) => {
          const count = localStorage.getItem("localDeliveryAddressCount");
          const newCount = count ? +count + 1 : 1;
          if (
            internalDeliveryForm.parcel_local_delivery === "1" &&
            internalDeliveryForm.local_delivery_type === 1 &&
            (newCount === 1 || (isPressedLocalAddress && newCount > 1))
          ) {
            localStorage.setItem(
              "localDeliveryAddress",
              internalDeliveryForm.local_delivery_full_address
            );
            localStorage.setItem(
              "localDeliveryCity",
              internalDeliveryForm.local_delivery_city
            );
            localStorage.setItem(
              "localDeliveryStatesId",
              internalDeliveryForm.local_delivery_states_id
            );
            localStorage.setItem("localDeliveryAddressCount", newCount);
          } else if (!parcelId) {
            localStorage.removeItem("localDeliveryAddress");
            localStorage.removeItem("localDeliveryCity");
            localStorage.removeItem("localDeliveryStatesId");
            localStorage.removeItem("localDeliveryAddressCount");
          }
          if (!parcelId) {
            localStorage.setItem("previousCustomerId", form.customer_id);
          }
          if (isPrinting === "print") {
            navigate(`/parcels/${listId}`, { state: res?.data?.parcel_id });
            localStorage.setItem("print", "print");
          } else if (isPrinting === "save") {
            navigate(`/parcels/${listId}`);
          } else {
            setForm(defaultForm);
            setPrinting("add");
            dispatchToast({
              type: "SUCCESS_TOAST",
              payload: {
                message: "Success action",
              },
            });
          }
        })
        .catch((err) => {
          if (typeof err.message === "string") {
            dispatchToast({
              type: "ERROR_TOAST",
              payload: { message: err.message },
            });
          } else {
            if (err.message.customer_id) {
              dispatchToast({
                type: "ERROR_TOAST",
                payload: { message: "Sender is required" },
              });
            } else if (err.message.receiver_id) {
              dispatchToast({
                type: "ERROR_TOAST",
                payload: { message: "Recipient is required" },
              });
            } else {
              setInputErrors(err.message);
            }
          }
        })
        .finally(() => {
          setIsSubmiting(false);
        });
    }
  };

  useEffect(() => {
    if (parcelId) {
      SERVICES.ORDERS.getParcels(listId, parcelId).then((res) => {
        const value = res.data[0];
        setForm({
          customer_id: value.customer_id,
          receiver_id: value.receiver_id,
          parcel_weight: value.parcel_weight,
          parcel_declared_value: value.parcel_declared_value,
          parcel_packaging: value.parcel_packaging,
          parcel_insurance: value.parcel_insurance,
          dimension_calculation: value.dimension_calculation,
          dimension_length: value.dimension_length,
          dimension_width: value.dimension_width,
          dimension_height: value.dimension_height,
          files: value.files,
          content: value.content,
          sender: value.sender,
          price_per_kg: value.price_per_kg,
          parcels_discount_type_id: value.parcels_discount_type_id,
          parcel_discount: value.parcel_discount,
          payment_cash: value.payment_cash,
          payment_bank_transfer: value.payment_bank_transfer,
          parcel_comments: value.parcel_comments,
          price: value.price,
          currency_type_id: value?.currency_type_id || 1,
          remainder: value.remainder,
        });
          setInternalDeliveryForm({
            parcel_local_delivery: value.parcel_local_delivery,
            ...((value.parcel_local_delivery === "1") && {
              local_delivery_states_id: value.local_delivery_states_id,
              local_delivery_full_address: value.local_delivery_full_address,
              local_delivery_address_type: value.local_delivery_address_type,
              local_delivery_price: value.local_delivery_price,
              parcel_weight: value.parcel_weight,
              local_delivery_city: value?.local_delivery_city,
              local_delivery_type: +value.local_delivery_type || 1,
              defaultForm: {
                local_delivery_states_id: value.local_delivery_states_id,
                local_delivery_full_address: value.local_delivery_full_address,
                local_delivery_price: value.local_delivery_price,
                local_delivery_city: value?.local_delivery_city,
                local_delivery_type: +value.local_delivery_type,
              },
            }),
          });
      });
    }
  }, [listId, parcelId]);

  return (
    <Grid container spacing={2} className={classes.parcelReservation}>
      <Grid item xs={12}>
        <SectionTitle title="Reservation" />
      </Grid>
      <SenderForm
        customerId={form.customer_id}
        isPrinting={isPrinting}
        setCustomerId={(val) => {
          setForm((prev) => ({ ...prev, customer_id: val }));
        }}
        setCurrency={(val) => {
          setForm((prev) => ({ ...prev, currency_type_id: val }));
        }}
        disabled={!!parcelId || !!form.customer_id}
        noEditable={!!warehouseId}
      />
      <RecipientForm
        customerId={form.receiver_id}
        isPrinting={isPrinting}
        setCustomerId={(val) => {
          setForm((prev) => ({ ...prev, receiver_id: val }));
        }}
        senderId={form.customer_id}
        disabled={!!parcelId}
        noEditable={!!warehouseId}
      />
      <ValidatorForm
        onSubmit={handleSubmit}
        onError={(errors) => console.log(errors)}
      >
        <Grid container rowSpacing={0.5} columnSpacing={2}>
          <GrossForm
            {...{
              form: {
                parcel_weight: form.parcel_weight,
                parcel_declared_value: form.parcel_declared_value,
                parcel_packaging: form.parcel_packaging,
                parcel_insurance: form.parcel_insurance,
                dimension_calculation: form.dimension_calculation,
                dimension_length: form.dimension_length,
                dimension_width: form.dimension_width,
                dimension_height: form.dimension_height,
                currency_type_id: form.currency_type_id,
                files: form.files,
              },
              handleChange,
              handleFileDelete,
              handleFileChange,
              inputErrors,
              disabled: !!parcelId,
            }}
          />
          <ContentForm
            {...{
              form: form.content,
              addContent,
              deleteContent,
              handleChange: handleContentChange,
              availableWeight:
                form.parcel_weight -
                form.content.reduce(
                  (weightSum, a) => +(weightSum + +a.weight).toFixed(2),
                  0
                ),
              availablePrice:
                form.parcel_declared_value -
                form.content.reduce(
                  (weightSum, a) => +(weightSum + +a.price).toFixed(2),
                  0
                ),
              grossWeightStatus,
              grossPriceStatus,
              currency_type_id: form.currency_type_id,
              disabled: !!parcelId,
            }}
          />
          <InternalDelivery
            {...{
              form: internalDeliveryForm,
              parcel_weight: form.parcel_weight,
              handleChange: handleInternalDeliveryChange,
              disabled: !!warehouseId,
              parcelId,
              setChanged: setChanged,
              setIsPressed,
              setForm: setInternalDeliveryForm,
              defFrom: defaultInternalDeliveryForm,
              isPressed: isPressedLocalAddress,
              currency_type_id: form.currency_type_id,
              customerId: form.receiver_id,
            }}
          />
          <PaymentForm
            {...{
              form: {
                price_per_kg: form.price_per_kg,
                parcel_weight: form.parcel_weight,
                parcels_discount_type_id: form.parcels_discount_type_id,
                parcel_discount: form.parcel_discount,
                payment_cash: form.payment_cash,
                payment_bank_transfer: form.payment_bank_transfer,
                parcel_comments: form.parcel_comments,
                currency_type_id: form.currency_type_id
              },
              totalPayment: totalPayment,
              remainder: remainder.toFixed(2),
              handleChange,
              inputErrors,
              disabled: !!parcelId,
              noEditable: !!warehouseId,
            }}
          />
          <Grid item xs={12} className={classes.saveBtn}>
            <div className={classes.saveBtnMob}>
              {!parcelId && (
                <Button
                  variant="contained"
                  className={classes.btnMob}
                  type="submit"
                  onClick={() => {
                    setPrinting("save&add");
                  }}
                  disabled={isSubmitting}
                >
                  Save & add
                </Button>
              )}
              {!parcelId && (
                <Button
                  variant="contained"
                  className={classes.btnMob}
                  type="submit"
                  onClick={() => {
                    setPrinting("print");
                  }}
                  disabled={isSubmitting}
                >
                  Save & print
                </Button>
              )}
              <Button
                variant="contained"
                className={classes.btnMob}
                type="submit"
                onClick={() => {
                  setPrinting("save");
                }}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Grid>
  );
};

export default ParcelReservation;
