import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  label: {
    margin: "15px 0 5px",
    textAlign: "left",
    fontWeight: 600,
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
  },
  phoneInput: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "12px",
    padding: "0px 15px",
    "& .PhoneInputInput": {
      border: "none",
      fontSize: 16,
      outline: "none",
      height: "1.4375em",
      padding: "7.5px 0",
    },
    "& .PhoneInput--focus": {
      border: "1px solid #13A800",
    },
    "& .PhoneInputCountrySelectArrow": {
      border: "none",
    },
  },
  saveBtn: {
    "&.MuiButtonBase-root": {
      marginTop: 20,
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 10,
      fontWeight: 700,
      fontSize: 16,
      border: "none",
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
        border: "none",
      },
    },
  },
  errorField: {
    position: "relative",
    "& p": {
      position: "absolute",
      bottom: -25,
      margin: "4px 14px 0px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
      color: "#d32f2f",
      display: "block!important",
    },
    "& .PhoneInput, .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d32f2f",
    },
  },
}));
