import { Button, Container, Menu, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import packageJSON from "../../../package.json";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2),
    marginTop: "auto",
    display: "flex",

    backgroundColor: theme.palette.primary.dark,
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 15px",
    },
    [theme.breakpoints.down(500)]: {
      flexDirection: "column",
    },
  },
  a: {
    marginRight: 12,
    textDecoration: "unset",
    fontWeight: 500,
    fontSize: 12,
    color: "rgba(255, 255, 255, 0.6)",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const Footer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm" className={classes.footer}>
        <>
          <Typography
            variant="body2"
            align="center"
            style={{ fontWeight: 700, fontSize: "14px" }}
          >
            2017-{new Date().getFullYear()} {t("footer.privacy")}
          </Typography>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              id="basic-button"
              className={classes.a}
              style={{ marginBottom: 4 }}
            >
              <a
                href="https://barekam.am/Terms-AM.pdf"
                target="_blank"
                rel="noreferrer"
                className={classes.a}
              >
                {t("footer.terms")}
              </a>
            </span>
            <span id="basic-button" className={classes.a}>
              <a
                href="https://barekam.am/Terms-EN.pdf"
                target="_blank"
                rel="noreferrer"
                className={classes.a}
              >
                Terms and Conditions
              </a>
            </span>
          </div>
        </>
      </Container>
      <div
        style={{
          padding: "0 5px",
          fontSize: 13,
          justifyContent: "center",
          display: "flex",
          opacity: 0.6,
          alignItems: "center",
        }}
      >
        v{packageJSON.version}
      </div>
    </footer>
  );
};

export default Footer;
