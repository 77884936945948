import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  fixedWidthContainer: {
    width: "87%",
    maxWidth: 1440,
    margin: "20px auto",
  },
  container: {
    maxWidth: 1440,
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    width: "65%",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontWeight: 600,
    fontSize: 36,
    lineHeight: 2,
    color: theme.palette.primary.main,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
    },
  },
  addBlock: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
      marginBottom: 15,
      width: "100%",
    },
  },
  addBtn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: "#FCB331",
      color: "white",
      borderRadius: 8,
      fontWeight: 400,
      fontSize: 18,
      padding: "5px 30px",
      margin: "0px 30px 0px 0px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: 0,
      },
      "&:hover": {
        background: "#FCB331",
      },
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
        padding: "5px 18px",
        margin: 0,
      },
    },
  },
  search: {
    margin: "20px 0px",
    display: "flex",
    width: "100% !important",
    border: "1px solid #13A800",
    borderRadius: "12px  !important",
    "&.MuiPaper-root": {
      boxShadow: "unset",
      boxSizing: "border-box",
    },
    "& .MuiIconButton-root": {
      color: "#A7A7A7",
    },
    [theme.breakpoints.down("sm")]: {
      height: 90,
    },
  },
  selectBlock: {
    display: "flex",
    position: "absolute",
    right: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "50%",
    },
  },
  citiesForm: {
    // display: "flex",
    // position: "absolute",
    // right: 200,
    // width: "10%",
    "& .MuiInputBase-root ": {
      background: "#F7F7F7",
      height: 35,
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover": {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  selectTitle: {
    fontSize: 18,
    color: "#A7A7A7",
  },
  totalBlock: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  table: {
    border: "1px solid #F2F2F2",
    borderRadius: 8,
    minHeight: 390,
    "&.MuiTableContainer-root": {
      boxShadow: "unset",
    },
    "& .MuiTableCell-head": {
      color: "#828282",
      fontWeight: 700,
      fontSize: 16,
    },
    "& .MuiTableBody-root": {
      height: 70,
    },
    "& .MuiSvgIcon-root": {
      transform: "rotate(90deg)",
      color: "#13A800",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      boxShadow: "0px 5px 12px rgba(90, 95, 105, 0.2)",
      borderRadius: 8,
      minWidth: 150,
      padding: "10px",
    },
  },
}));
