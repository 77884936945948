import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import bag_icon from "assets/icons/bag.png";
import { useStyles } from "./styles";
import { ImageUpload } from "components/ImageUpload";
import { useTranslation } from "react-i18next";
import { currencyTypes } from "pages/Parcels/components/Parcel";

const GrossForm = ({
  form,
  handleChange,
  handleFileDelete,
  handleFileChange,
  inputErrors,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <img src={bag_icon} alt="icon" className={classes.icon} />
            <span>Gross information</span>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.label}>
          Gross weight (kg) <span className={classes.asterisk}>*</span>
        </div>
        <TextValidator
          size="small"
          fullWidth
          name="parcel_weight"
          value={form.parcel_weight}
          onChange={(event) => {
            const val =
              event.target.value.startsWith("0") &&
              event.target.value[1] !== "." &&
              event.target.value.length > 1
                ? event.target.value.substring(1, event.target.value.length)
                : event.target.value;
            !isNaN(val) &&
              handleChange({ target: { value: val, name: "parcel_weight" } });
          }}
          validators={["required"]}
          errorMessages={['Required field.']}
          helperText={inputErrors.parcel_weight}
          error={!!inputErrors.parcel_weight}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <div className={classes.label}>
          {/* Total value (euro) <span className={classes.asterisk}>*</span> */}
          Total value ({currencyTypes[form.currency_type_id]}) <span className={classes.asterisk}>*</span>
        </div>
        <TextValidator
          size="small"
          fullWidth
          name="parcel_declared_value"
          value={form.parcel_declared_value}
          onChange={(event) => {
            const val =
              event.target.value.startsWith("0") &&
              event.target.value[1] !== "." &&
              event.target.value.length > 1
                ? event.target.value.substring(1, event.target.value.length)
                : event.target.value;
            !isNaN(val) &&
              handleChange({
                target: { value: val, name: "parcel_declared_value" },
              });
          }}
          validators={["required"]}
          errorMessages={['Required field.']}
          helperText={inputErrors.parcel_declared_value}
          error={!!inputErrors.parcel_declared_value}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <FormControl style={{ width: "100%" }}>
          <div className={classes.label}>
            Package type <span className={classes.asterisk}>*</span>
          </div>
          <RadioGroup
            row
            name="parcel_packaging"
            value={form.parcel_packaging}
            onChange={handleChange}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#13A800",
                    },
                  }}
                />
              }
              label="Box"
              disabled={disabled}
            />
            <FormControlLabel
              value="2"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#13A800",
                    },
                  }}
                />
              }
              label="Bag"
              disabled={disabled}
            />
            <FormControlLabel
              value="3"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#13A800",
                    },
                  }}
                />
              }
              label="Other"
              disabled={disabled}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={4}>
        <FormControl style={{ width: "100%" }}>
          <div className={classes.label}>
            Insurance <span className={classes.asterisk}>*</span>
          </div>
          <RadioGroup
            row
            name="parcel_insurance"
            value={form.parcel_insurance}
            onChange={handleChange}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#13A800",
                    },
                  }}
                />
              }
              label="Yes"
              disabled={disabled}
            />
            <FormControlLabel
              value="2"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#13A800",
                    },
                  }}
                />
              }
              label="No"
              disabled={disabled}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={4}>
        <FormControl style={{ width: "100%" }}>
          <div className={classes.label}>
            Dimension calculation <span className={classes.asterisk}>*</span>
          </div>
          <RadioGroup
            row
            name="dimension_calculation"
            value={form.dimension_calculation}
            onChange={handleChange}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#13A800",
                    },
                  }}
                />
              }
              label="Yes"
              disabled={disabled}
            />
            <FormControlLabel
              value="2"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#13A800",
                    },
                  }}
                />
              }
              label="No"
              disabled={disabled}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {form.dimension_calculation === "1" && (
        <>
          <Grid item xs={12} lg={4}>
            <div className={classes.label}>
              Length (cm) <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              size="small"
              fullWidth
              name="dimension_length"
              value={form.dimension_length}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={['Required field.']}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <div className={classes.label}>
              Width (cm) <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              size="small"
              fullWidth
              name="dimension_width"
              value={form.dimension_width}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={['Required field.']}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <div className={classes.label}>
              Height (cm) <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              size="small"
              fullWidth
              name="dimension_height"
              value={form.dimension_height}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={['Required field.']}
              disabled={disabled}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} lg={6}>
        <ImageUpload
          {...{
            label: "Upload Parcel Image",
            handleFileChange,
            files: form.files,
            handleFileDelete,
            disabled,
          }}
        />
      </Grid>
    </>
  );
};

export default GrossForm;
