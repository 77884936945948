import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "context/Auth.context";
import SettingsProvider from "context/Settings.context";
import "./i18n";

const theme = createTheme({
  palette: {
    primary: {
      main: "#13A800",
      dark: "#324827",
      light: "#A3FF97",
      contrastText: "#26341F",
    },
    secondary: {
      main: "#FCB331",
      light: "#FFBF4E",
      contrastText: "#A7A7A7",
    },
    error: {
      main: "#FF0B21",
    },
  },
  overrides: {
    MuiButton: {
      label: {
        color: "#f1f1f1",
      },
      raisedPrimary: {
        color: "white",
      },
    },
  },
  typography: {
    fontFamily: ['"Gilroy ", sans-serif'].join(","),
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SettingsProvider>
    <AuthProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </AuthProvider>
  </SettingsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
