import { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  FormControlLabel,
  Radio,
  Grid,
  RadioGroup,
} from "@mui/material";
import close_icon from "assets/icons/close.png";
import { useStyles } from "./styles";
import Loading from "components/Loading";
import PhoneInput from "react-phone-number-input";
import PhoneField from "components/PhoneField";

const initialState = {
  director_firstname: "",
  director_lastname: "",
  contract_id: "",
  postal_code: "",
  telephone: "",
};

export default function CustomerSelect({
  label,
  icon,
  onCustomerChange,
  clearCustomer,
  setCurrency,
  customerId,
  setCustomerId,
  customers,
  getCustomers,
  disableFind
}) {
  const classes = useStyles();
  const [openSearch, setOpenSearch] = useState(false);
  const [form, setForm] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const newParams =
      form.director_firstname === "" &&
      form.director_lastname === "" &&
      form.contract_id === "" &&
      form.postal_code === "" &&
      form.telephone === ""
        ? undefined
        : form;
    getCustomers(newParams).finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (customerId) {
      const customer = customers.find((el) => el.customer_id === customerId);
      if (customer) {
        onCustomerChange &&
          onCustomerChange({
            firstname: customer.director_firstname || "",
            lastname: customer.director_lastname || "",
            country_id: customer.country_id,
            city: customer.city || "",
            postal_code: customer.postal_code || "",
            address: customer.customer_address || "",
            telephone: customer.telephone || "",
            email: customer.email || "",
            states_id: customer.states_id || "",
            contract_id: customer.contract_id || "",
            company_name: customer.company_name || "",
            taxpayer_id: customer.taxpayer_id || 0,
            other_states_name: customer.other_states_name || "",
            country_code: customer.country_code || "",
            passport_dateOfIssue: customer.passport_dateOfIssue || null,
            passport_number: customer.passport_number || "",
            sender: customer
          });
      }
    }
  }, [customerId, onCustomerChange, customers]);

  return (
    <>
      {openSearch ? (
        <div className={classes.customerSelect}>
          <img
            className={classes.closeIcon}
            src={close_icon}
            alt="close"
            onClick={() => {
              clearCustomer();
              setForm(initialState);
              setOpenSearch(false);
            }}
          />
          <Grid container rowSpacing={0.5} columnSpacing={2}>
            <Grid item xs={12} md={4} lg={2}>
              <div className={classes.label}>Name</div>
              <TextField
                size="small"
                fullWidth
                name="director_firstname"
                value={form.director_firstname || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <div className={classes.label}>Surname</div>
              <TextField
                size="small"
                fullWidth
                name="director_lastname"
                value={form.director_lastname || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <div className={classes.label}>Post Index</div>
              <TextField
                size="small"
                fullWidth
                name="postal_code"
                value={form.postal_code || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <div className={classes.label}>Phone</div>
              <PhoneField
                {...{
                  value: form.telephone,
                  setForm,
                  isRequired: false,
                  hideLabel: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <div className={classes.label}>ID</div>
              <TextField
                size="small"
                fullWidth
                name="contract_id"
                value={form.contract_id || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={2}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                className={classes.btn}
                onClick={handleSubmit}
              >
                Find
              </Button>
            </Grid>
            <Grid item xs={12}>
              {isLoading ? (
                <Loading />
              ) : customers.length ? (
                <RadioGroup
                  value={customerId}
                  className={classes.customerRadioGroup}
                  onChange={(event, value) => {
                    setCustomerId(value || null);
                  }}
                >
                  {customers.map((option) => (
                    <FormControlLabel
                      key={option.customer_id}
                      value={option.customer_id}
                      className={classes.customerLabel}
                      control={
                        <Radio
                        onChange={() => {
                          setCurrency && setCurrency(option.country_name === 'Ploand' || option.country_id === '179' ? "2" : "1")}}
                          sx={{
                            "&.Mui-checked": {
                              color: "#13A800",
                            },
                          }}
                        />
                      }
                      label={
                        <Box component="li" className={classes.customerBox}>
                          {option.director_firstname +
                            " " +
                            option.director_lastname +
                            " | " +
                            option.country_name +
                            " " +
                            option.country_code +
                            " " +
                            option.city +
                            " | " +
                            option.postal_code +
                            " | " +
                            option.customer_address +
                            " | " +
                            option.email +
                            " | " +
                            option.telephone}
                        </Box>
                      }
                    />
                  ))}
                </RadioGroup>
              ) : (
                <div className={classes.noData}>No data</div>
              )}
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.customerSelectLayer}>
          <div className={classes.title}>
            <img src={icon} alt="icon" className={classes.icon} />
            <span>{label}</span>
          </div>
          {!disableFind && (
            <Button
              variant="outlined"
              className={classes.findBtn}
              onClick={() => setOpenSearch(true)}
            >
              Find in list
            </Button>
          )}
        </div>
      )}
    </>
  );
}
