import {
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiFormLabel-asterisk": {
      color: "#FF0B21",
      fontSize: 18,
      fontWeight: 700,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
}));

const ConfirmDialog = ({ dialog, setDialog }) => {
  const classes = useStyles();
  const [submit, setSubmit] = useState(false);
  const [form, setForm] = useState({});
  const { t } = useTranslation();
  const handleClose = () => {
    setDialog(null);
    setForm({});
  };

  const handleSubmit = () => {
    setSubmit(true);
    dialog?.callback &&
      dialog
        .callback(form)
        .then(() => handleClose())
        .finally(() => setSubmit(false));
  };
  return (
    <Dialog
      open={dialog?.open || false}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{dialog?.title}</DialogTitle>
      <DialogContentText
        style={{
          textAlign: "center",
          fontSize: 18,
          paddingTop: 10,
          paddingBottom: 20,
        }}
      >
        {dialog?.subtitle}
      </DialogContentText>
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogContentText style={{ padding: 10 }}>
          <TextValidator
            className={classes.input}
            fullWidth
            name="comment"
            variant="outlined"
            margin="dense"
            label={
              <>
                Comment <span style={{ color: "red" }}>*</span>
              </>
            }
            type="text"
            validators={["required", "minStringLength:5"]}
            errorMessages={[t("global.required"), "Min 5 characters"]}
            value={form.comment}
            onChange={(event) =>
              setForm((prev) => ({ ...prev, comment: event.target.value }))
            }
          />
        </DialogContentText>

        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#EF4444" }}>
            Cancel
          </Button>
          <Button type="submit" disabled={submit} style={{ color: "#13A800" }}>
            {submit ? <CircularProgress size={20} /> : "Confirm"}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

export default ConfirmDialog;
