import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  uploadImageBtn: {
    "&.MuiButtonBase-root": {
      width: "100%",
      textTransform: "unset",
      color: theme.palette.primary.main,
      borderRadius: 12,
      fontWeight: 400,
      fontSize: 18,
      padding: "5px 30px",
      margin: "0px 30px 0px 0px",
      borderColor: "#CDCDCD",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: 0,
      },
      "&:hover": {
        background: "white",
        borderColor: "#CDCDCD",
      },
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
        padding: "5px 18px",
        margin: 0,
      },
    },
  },
  uploadImageWrapper: {
    display: "flex",
    flexFlow: "wrap",
  },
  imageItem: {
    position: "relative",
    margin: "10px 15px 0 0",
  },
  deleteBtn: {
    position: "absolute",
    top: -8,
    right: -10,
    width: 28,
    height: 28,
    background: "red",
    color: "white",
    borderRadius: "50%",
    fontSize: 20,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
